import FdicLogo from "../../assets/FDICLogoWhite.svg";
export const FdicComponent = () => {
    return (
        <div className="fdic-container">
            <img className="fdic-logo" title="FdicLogo" src={FdicLogo}/>
            <span className="fdic-logo-text">FDIC-Insured - Backed by the full faith and credit of the U.S. Government</span>
        </div>
    )
    
  };
  